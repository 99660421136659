/**
 * Middleware for protected routes
 */
import { useCheckoutStore } from "~/stores/checkout.store";

export default defineNuxtRouteMiddleware((to, from) => {
  const { cartContext } = storeToRefs(useCheckoutStore());

  // Initial Request to Nuxt
  if (!cartContext.value?.cart && to.path === from.path) {
    return navigateTo({
      name: "checkIn",
      query: {
        r: to.path,
        baseSite: to.params.baseSite,
      },
    });
  }
});
